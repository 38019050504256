import { Dropdown } from 'antd';
import classNames from 'classnames';

import { CategoryTopic } from 'src/interface';

import DropdownMenu from './DropdownMenu';

import classes from './TabMenu.module.less';

type Props = {
	activeKey: any;
	onChange: (v: any) => void;
	categoryTopics: any;
	keyTopic: any;
};

const TabMenu = (props: Props) => {
	const { activeKey, onChange, categoryTopics, keyTopic } = props;

	return (
		<div className={classes.wrapper}>
			<div className={classes.tabs}>
				{categoryTopics?.slice(0, keyTopic)?.map((categoryTopic: CategoryTopic, id: number) => {
					const key = `${id + 1}__${categoryTopic.slug}`;
					const isActiveKey = key === activeKey;

					return (
						<div
							key={key}
							className={classNames(classes.tab, { [classes.active]: isActiveKey })}
							onClick={() => onChange({ key })}
						>
							<h2>{categoryTopic?.name}</h2>
						</div>
					);
				})}

				{categoryTopics?.length > keyTopic && (
					<Dropdown
						overlay={
							<DropdownMenu
								menu={categoryTopics}
								active={activeKey}
								onChangeFn={onChange}
								keyTopic={keyTopic}
							/>
						}
						trigger={['click']}
						placement="bottomRight"
						arrow
					>
						<div
							className={classNames(classes.other, {
								[classes.active]: activeKey?.split('__')?.[0] > keyTopic,
							})}
							onClick={(e) => e.preventDefault()}
						>
							Lainnya
						</div>
					</Dropdown>
				)}
			</div>
		</div>
	);
};

export default TabMenu;
