import { FC } from 'react';
import Link from 'next/link';
import { Divider, Grid } from 'antd';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import { IArticleByGroupDetail } from 'src/interface';
import AuthStorage from 'src/utils/auth-storage';
import { formatDate, googleArticleEvent } from 'src/utils/func';

import classes from './MainArticle.module.less';

interface MainArticleProps {
	data: IArticleByGroupDetail;
	subCategory?: string;
	headingLevel?: any;
}

const MainArticle: FC<MainArticleProps> = (props: MainArticleProps) => {
	const breakpoint = Grid.useBreakpoint();
	const userAuth = AuthStorage.tokenDecode as any;

	const imageUrl = props.data?.media_images?.find((i) => !!i?.['ar-desktop'])?.['ar-desktop'];

	const getArticleCat = (item: any) => {
		const getCat = item.cmp_article_category?.find((x: any) => x.is_primary)?.category;

		if (getCat) {
			return getCat;
		}

		return {
			full_slug: '',
			name: '-',
		};
	};

	const getArticleSlug = () => {
		const subCategory = props.subCategory;
		const slug = props.data?.slug;
		const url = props.data?.url;

		if (subCategory && !['latest', 'pilihan'].includes(subCategory)) {
			return `${subCategory?.startsWith('/') ? '' : '/'}${subCategory}/${slug}`;
		} else {
			return `/${url}`;
		}
	};

	const onClickArticle = (item: IArticleByGroupDetail) => {
		googleArticleEvent(item, userAuth, 'click');
	};

	return (
		<>
			{props.data && (
				<div className={classes.main}>
					<div className={classes['container-img']}>
						{imageUrl && <ImageC alt={props.data?.title} src={imageUrl} width={730} height={411} />}
					</div>
					<div className={classes['main-detail']}>
						<a onClick={() => onClickArticle(props.data)} href={getArticleSlug()}>
							<Title
								level={props.headingLevel || 3}
								text={props.data?.title}
								className={classes['main-title']}
							/>
						</a>
						<p className={classes['main-category']}>
							<Link href={`/${getArticleCat(props.data).full_slug}`} legacyBehavior>
								{getArticleCat(props.data).name}
							</Link>
							{formatDate(props.data?.publish_date, 'DD MMM YYYY')}
						</p>
						<p className={classes['main-desc']}>{props.data?.short_desc}</p>
					</div>
					{breakpoint.xs && <Divider />}
				</div>
			)}
		</>
	);
};

export default MainArticle;
