import { Menu } from 'antd';

import { CategoryTopic } from 'src/interface/Auth/Register/RegisterUser';

import classes from './DropdownMenu.module.less';

const DropdownMenu = (props: any) => {
	return (
		<Menu className={classes.dropdown} selectedKeys={props.active} onClick={props.onChangeFn}>
			{props.menu?.slice(props.keyTopic, 100)?.map((item: CategoryTopic, id: number) => {
				return (
					<Menu.Item key={`${id + props.keyTopic + 1}__${item.slug}`}>
						<a href={item.full_slug}>{item.name}</a>
					</Menu.Item>
				);
			})}
		</Menu>
	);
};

export default DropdownMenu;
