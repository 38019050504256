import dynamic from 'next/dynamic';
import { useDetectAdBlock } from 'adblock-detect-react';

import ImageC from 'src/components/Image';
import { PromoProps } from 'src/interface';
import { TargetArrayType } from 'src/interface/AdSlot/index';

import classes from './Advertising.module.less';

type Props = {
	ads: PromoProps[];
	adUnits: [string | undefined, string | undefined];
	sizes: any;
	adSource: string;
	adTarget: TargetArrayType;
};

const ratioTmp = [
	{
		width: 350,
		height: 700,
	},
	{
		width: 350,
		height: 292,
	},
];

const GPT = dynamic(() => import('src/components/GPT'), { ssr: false });
const Advertising = (props: Props) => {
	const adBlockDetected = useDetectAdBlock();
	const { ads, adSource, adTarget, adUnits, sizes } = props;

	return !adBlockDetected ? (
		<div className={classes['google-ads']}>
			<GPT
				name={`gpt-ad-${adSource}-halfpage`}
				adUnit={adUnits[0]}
				size={sizes[0]}
				target={adTarget}
				className={classes.ads}
			/>
			<GPT
				name={`gpt-ad-${adSource}-mrec`}
				adUnit={adUnits[1]}
				size={sizes[1]}
				target={adTarget}
				className={classes.ads}
			/>
		</div>
	) : (
		<>
			{ads?.length > 0 &&
				ads?.map((item: PromoProps, id: number) => (
					<a key={id} href={item.url} className={classes['internal-ads']}>
						<div className={classes.ads}>
							<ImageC
								className={classes['ads-img']}
								alt={item.name}
								src={item.banner_img}
								layout="responsive"
								objectFit="contain"
								width={ratioTmp[id].width}
								height={ratioTmp[id].height}
							/>
						</div>
					</a>
				))}
		</>
	);
};

export default Advertising;
