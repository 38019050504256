import Link from 'next/link';
import { Col, Divider, Grid, Row } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import { IArticleByGroupDetail } from 'src/interface';
import AuthStorage from 'src/utils/auth-storage';
import { formatDate, getProfileListImage, googleArticleEvent } from 'src/utils/func';

import classes from './CardArticle.module.less';

type Props = {
	data: IArticleByGroupDetail[];
	length?: number;
	subCategory?: string;
	hideHeadingTitle?: boolean;
	showLabel?: boolean;
	headingLevel?: any;
	hideImage?: boolean;
};

const CardArticle = (props: Props) => {
	const breakpoint = Grid.useBreakpoint();
	const userAuth = AuthStorage.tokenDecode as any;

	const getArticleCat = (item: any) => {
		const getCat = item.cmp_article_category?.find((x: any) => x.is_primary)?.category;

		if (getCat) {
			return getCat;
		}

		return {
			full_slug: '',
			name: '-',
		};
	};

	const getArticleSlug = (index: number) => {
		const subCategory = props.subCategory;
		const slug = props.data[index]?.slug;
		const url = props.data[index]?.url;

		if (subCategory && !['latest', 'pilihan'].includes(subCategory)) {
			return `${subCategory?.startsWith('/') ? '' : '/'}${subCategory}/${slug}`;
		} else {
			return `/${props.data[index]?.type != 'general' ? props.data[index]?.type + '/' + url : url}`;
		}
	};

	const renderProfileImage = (
		item: IArticleByGroupDetail,
		media_images: any[],
		thumbnail_url: string,
	) => {
		const url = getProfileListImage(media_images || [], thumbnail_url, breakpoint) ?? '';
		const getImgSize = () => {
			if (breakpoint.xl) return 95;
			if (breakpoint.lg) return 128;
			if (breakpoint.sm) return 104;
			return 80;
		};

		return (
			url && (
				<ImageC
					className={classes['list-img']}
					alt={item?.title}
					src={url}
					width={getImgSize()}
					height={getImgSize()}
					layout="fixed"
				/>
			)
		);
	};

	const onClickArticle = (item: IArticleByGroupDetail) => {
		googleArticleEvent(item, userAuth, 'click');
	};

	return (
		<>
			{props.data?.length >= 1 &&
				props.data
					?.filter((card: any, index: any) => index != 0 || props.showLabel)
					.map((item: IArticleByGroupDetail, id: number) => (
						<div className="column-article" key={id}>
							<Row className={classes.list} gutter={breakpoint.xs ? [12, 8] : [24, 8]} wrap={false}>
								{!props.hideImage && (
									<Col>
										{renderProfileImage(item, item.media_images || [], item?.thumbnail_url)}
									</Col>
								)}
								<Col className={classes['list-detail']}>
									<div className="d-flex flex-column justify-content-between align-items-start">
										{props.showLabel && (
											<Link
												href={`${
													item.type != 'general' ? `/${item.type}` : ''
												}/${item.categories?.find((x: any) => x.is_current_category)?.full_slug}`}
												className={classes['list-category-related']}
											>
												<p>{item.categories?.find((x: any) => x.is_current_category)?.name}</p>
											</Link>
										)}
										<a
											href={getArticleSlug(id + (props.showLabel ? 0 : 1))}
											onClick={() => onClickArticle(item)}
										>
											{props.hideHeadingTitle ? (
												<p
													className={classNames(`${classes['list-title']} mb-1`, {
														[classes['list-title-related']]: props.showLabel,
													})}
												>
													{item.title}
												</p>
											) : (
												<Title
													level={props.headingLevel || 3}
													text={item.title}
													className={classNames(`${classes['list-title']} mb-1`, {
														[classes['list-title-related']]: props.showLabel,
													})}
												/>
											)}
										</a>
										{!props.showLabel && (
											<p className={classes['list-category']}>
												<Link href={`/${getArticleCat(item).full_slug}`} legacyBehavior>
													{getArticleCat(item).name}
												</Link>
												{formatDate(item.publish_date, 'DD MMM YYYY')}
											</p>
										)}
										{!breakpoint.xs && !props.showLabel && (
											<p className={classes['list-desc']}>{item.short_desc}</p>
										)}
										{props.showLabel && (
											<p className={classes['list-category-related']}>
												{formatDate(item.publish_date, 'DD MMM YYYY')}
											</p>
										)}
									</div>
								</Col>
							</Row>
							{breakpoint.xs && <Divider className={classes['list-divider']} />}
						</div>
					))}
		</>
	);
};

CardArticle.defaultProps = {
	showLabel: false,
};
export default CardArticle;
